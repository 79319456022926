<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "",
            items: [],
            loading: false,
            messagelist: [],
            page: 1,
            userinfo: [],
            admincard: [],
            tabsname: "bracelet",
        };
    },
    mounted() {
        this.title = this.$t("menuitems.setting.admins.text");
        this.items = [
            {
                text: this.$t("menuitems.setting.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.setting.admins.text"),
                active: true,
            },
        ];
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.$bus.$on('cardData', (data) => {
            const exists = this.admincard.some(item => item.hex === data);

            if (!exists) {
                // 如果不存在相同的 hex 数据，则添加
                this.admincard.push({ hex: data, name: '' });
            } else {
                // 可以执行其他逻辑，比如提醒用户数据已存在
                this.$message({
                    message: this.$t("menuitems.setting.admins.cardhex") + data + this.$t("global.exists"),
                    type: "warning",
                });
            }
        });
        this.getadminsbracelet();
    },
    methods: {
        addConfig() {
            this.admincard.push({ hex: '', name: '' });
        },
        removeConfig(index) {
            this.admincard.splice(index, 1);
        },
        getadminsbracelet(){
            var that = this
            that.loading = true;
            that.$axios
                .post(that.apiuri, { action: "getadminsbracelet" })
                .then((response) => {
                    that.loading = false;
                    if (response.data.status != 200) {
                        that.$message({
                            message: that.$t("global.failed"),
                            type: "error",
                        });
                        return;
                    }
                    that.admincard = response.data.data;
                })
                .catch((error) => {
                    that.loading = false;
                    that.$message({
                        message: that.$t("global.error"),
                        type: "error",
                    });
                });
        },
        saveadmins(route) {
            var that = this
            that.loading = true;
            that.$axios
                .post(that.apiuri, { action: `saveadmins${route}`, data: that.admincard })
                .then((response) => {
                    that.loading = false;
                    if (response.data.status != 200) {
                        that.$message({
                            message: that.$t("global.failed"),
                            type: "error",
                        });
                        return;
                    }
                    that.$message({
                        message: that.$t("global.success"),
                        type: "success",
                    });
                    that.getadminsbracelet()
                })
                .catch((error) => {
                    that.loading = false;
                    that.$message({
                        message: that.$t("global.error"),
                        type: "error",
                    });
                });
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-loading="loading">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <el-tabs v-model="tabsname">
                            <el-tab-pane :label="$t('menuitems.setting.admins.card')" name="bracelet">
                                <p>
                                    <el-button @click="addConfig">{{ $t('menuitems.setting.admins.addcard')
                                        }}</el-button>
                                </p>
                                <el-form ref="admincard" label-width="80px">
                                    <el-form-item v-for="(val, idx) in admincard"
                                        :label="$t('menuitems.setting.admins.cardnumber') + (idx + 1)" :key="idx">
                                        <div class="config-item">
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-input v-model="val.hex"
                                                        :placeholder="$t('menuitems.setting.admins.cardhex')"></el-input>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-input v-model="val.name"
                                                        :placeholder="$t('menuitems.setting.admins.remark')"></el-input>
                                                </el-col>
                                                <el-col :span="4">
                                                    <el-button @click="removeConfig(idx)">{{
                                                        $t('marketing.billing.remove')
                                                        }}</el-button>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="!admincard || admincard.length == 0">
                                        <el-empty :image-size="80" :description="$t('device.error.nodata')"></el-empty>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="saveadmins(tabsname)">{{
                                            $t('marketing.billing.save') }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>